import React, { useState } from "react"
import CookieConsent from "react-cookie-consent"
import Cookies from "universal-cookie"
import { Modal, Collapse, Container, Row, Col } from "react-bootstrap"
import { ArrowDownCircle, ArrowUpCircle } from "react-bootstrap-icons"
import Switch from "react-switch"

const cookies = new Cookies()

const CookieCon = () => {
  const [openNeccessary, setOpenNeccessary] = useState(false)
  const [openAnalytics, setOpenAnalytics] = useState(false)
  const [analyticsConsent, setAnalyticsConsent] = useState(true)
  const [cookieAccepted, setCookieAccepted] = useState(false)

  const handleAccept = () => {
    if (analyticsConsent) {
      cookies.set("gatsby-gdpr-google-analytics", true, {
        path: "/",
        sameSite: "Lax",
        expires: new Date(Date.now() + 3.154e10),
      })
    } else {
      cookies.set("gatsby-gdpr-google-analytics", false, {
        path: "/",
        sameSite: "Lax",
        expires: new Date(Date.now() + 3.154e10),
      })
    }
    setCookieAccepted(true)
  }

  return (
    <Modal
      show={
        cookieAccepted || cookies.get("cookie-consent-closed") === "true"
          ? false
          : true
      }
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header>
        <Container>
          <Row>
            <Col xs={{ span: 12 }}>
              <Modal.Title>We use cookies</Modal.Title>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 12 }}>
              We use cookies to enhance the browsing experience for our users.
              Accept and manage your preferred cookie settings below.
            </Col>
          </Row>
        </Container>
      </Modal.Header>

      <Modal.Body>
        <CookieConsent
          buttonText="Save and Continue"
          cookieName="cookie-consent-closed"
          disableStyles={true}
          buttonStyle={{ display: "flex" }}
          buttonClasses="btn btn-outline-success"
          onAccept={() => {
            handleAccept()
          }}
        >
          <Container>
            <Row
              className="align-items-center"
              style={{
                marginTop: "8px",
                marginBottom: "20px",
                alignSelf: "flex-end",
              }}
            >
              <Col
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
                style={{ paddingLeft: "0px" }}
              >
                Strictly neccessary
                {openNeccessary ? (
                  <ArrowUpCircle
                    style={{ marginLeft: "5px" }}
                    onClick={() => setOpenNeccessary(!openNeccessary)}
                  />
                ) : (
                  <ArrowDownCircle
                    style={{ marginLeft: "5px" }}
                    onClick={() => setOpenNeccessary(!openNeccessary)}
                  />
                )}
              </Col>
              <Col
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 7 }}
                lg={{ span: 7 }}
                xl={{ span: 7 }}
                className="text-right"
              >
                <Switch
                  onChange={() => {}}
                  checked
                  disabled
                  className="react-switch"
                  aria-label="Neccessary cookies turned on. "
                />
              </Col>

              <Collapse in={openNeccessary}>
                <Col
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                  style={{ paddingLeft: "0px" }}
                >
                  <div id="example-collapse-text">
                    These cookies are required to provide basic functionality
                    when using the website and cant be turned off.
                  </div>
                </Col>
              </Collapse>
            </Row>
            <Row>
              <Col
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 4 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
                style={{ paddingLeft: "0px" }}
              >
                Analytics
                {openAnalytics ? (
                  <ArrowUpCircle
                    style={{ marginLeft: "5px" }}
                    onClick={() => setOpenAnalytics(!openAnalytics)}
                  />
                ) : (
                  <ArrowDownCircle
                    style={{ marginLeft: "5px" }}
                    onClick={() => setOpenAnalytics(!openAnalytics)}
                  />
                )}
              </Col>

              <Col
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 7 }}
                lg={{ span: 7 }}
                xl={{ span: 7 }}
                className="text-right"
              >
                <Switch
                  onChange={() => {
                    setAnalyticsConsent(!analyticsConsent)
                  }}
                  checked={analyticsConsent}
                  className="react-switch"
                  aria-label="Analytics cookie consent."
                />
              </Col>

              <Collapse in={openAnalytics}>
                <Col
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                  style={{ paddingLeft: "0px" }}
                >
                  <div id="example-collapse-text">
                    These cookies are used to monitor website visits and
                    traffic, which allows us to improve and optimise the
                    performance of our site.
                  </div>
                </Col>
              </Collapse>
            </Row>
          </Container>
        </CookieConsent>
      </Modal.Body>
    </Modal>
  )
}

export default CookieCon
