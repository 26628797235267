import React from "react"

import "@fontsource/special-elite"
import { Container, Row, Col } from "react-bootstrap"

import Navbar from "./navBar"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import CookieConsent from '../components/CookieCon'

const getImages = graphql`
  {
    fluid: file(relativePath: { eq: "back1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(getImages)

  return (
    <BackgroundImage Tag="section" fluid={data.fluid.childImageSharp.fluid}>
      <Container
        fluid
        className="px-0 main"
        style={{
          backgroundImage: 'url("/images/back1.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar />

        <Row noGutters>
          <Col>
            <Container
              className="mt-7"
              style={{ marginTop: "15px", minHeight: "1200px" }}
            >
              <CookieConsent />
              <main>{children}</main>
            </Container>
          </Col>
        </Row>
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <footer>
                <span>
                  {`© ${new Date().getFullYear()} - Pamela Mary Brown`}
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </Container>
    </BackgroundImage>
  )
}

export default Layout
